var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      class: ["iq-select", "w-100p", _vm.colorTypeClass],
      attrs: {
        disabled: _vm.disabled,
        multiple: _vm.isMultiple,
        "collapse-tags": _vm.isCollapseTags,
        filterable: _vm.isFilterable,
        "allow-create": "",
        "default-first-option": "",
        placeholder: _vm.placeholder,
      },
      on: {
        change: _vm.handleChange,
        "visible-change": _vm.handleVisibleChange,
        "remove-tag": _vm.handleRemoveTag,
        focus: function ($event) {
          return _vm.$emit("focus")
        },
      },
      model: {
        value: _vm.inputVal,
        callback: function ($$v) {
          _vm.inputVal = $$v
        },
        expression: "inputVal",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item[_vm.keyLabel], value: item[_vm.keyValue] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }