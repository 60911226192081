<template>
  <el-select
    v-model="inputVal"
    :disabled="disabled"
    :class="['iq-select', 'w-100p', colorTypeClass]"
    :multiple="isMultiple"
    :collapse-tags="isCollapseTags"
    :filterable="isFilterable"
    allow-create
    default-first-option
    :placeholder="placeholder"
    @change="handleChange"
    @visible-change="handleVisibleChange"
    @remove-tag="handleRemoveTag"
    @focus="$emit('focus')"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item[keyLabel]"
      :value="item[keyValue]"
    />
  </el-select>
</template>

<script>
export default {
  name: 'IqSelect',
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isCollapseTags: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      default: () => [
        {
          id: 89,
          unload_id: 11,
          name: 'Пшеница. Протеин менее 12 20222',
          harvest_year: '20222',
        },
        {
          id: 88,
          unload_id: 11,
          name: 'Пшеница. Протеин более 12 20222',
          harvest_year: '20222',
        },
        {
          id: 87,
          unload_id: 11,
          name: 'Пшеница. Протеин более 14 20222',
          harvest_year: '20222',
        },
        {
          id: 86,
          unload_id: 11,
          name: 'Кукуруза 20222',
          harvest_year: '20222',
        },
        { id: 85, unload_id: 11, name: 'Ячмень 20222', harvest_year: '20222' },
        { id: 84, unload_id: 11, name: 'Соя 20222', harvest_year: '20222' },
        { id: 83, unload_id: 11, name: 'Горох 20222', harvest_year: '20222' },
        { id: 82, unload_id: 11, name: 'Пшеница 2022', harvest_year: '2022' },
      ],
      type: Array,
      require: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    colorTypeClass: {
      type: String,
      default: '',
    },
    keyLabel: {
      type: String,
      default: 'name',
    },
    keyValue: {
      type: String,
      default: 'id',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    document
      .querySelectorAll('.el-select .el-input input')
      .forEach(e => (e.readOnly = true))
  },
  methods: {
    handleChange(event) {
      this.$emit('onChange', event)
    },
    handleVisibleChange(event) {
      this.$emit('onVisibleChange', event)
    },
    handleRemoveTag(event) {
      this.$emit('onRemoveTag', event)
    },
  },
}
</script>

<style lang="sass">
.iq-select
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary
  .el-select__caret.el-icon-arrow-up
    &:before
      content: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3335 0.666748L6.00016 5.33342L10.6668 0.666748' stroke='%23606266'/%3E%3C/svg%3E%0A") !important
  .el-input
    .el-select__caret
      transform: rotateZ(0deg)
      &.is-reverse
        transform: rotateZ(180deg)
  &.gray
    .el-input__inner
      background: #F5F7FA
      border: 1px solid #DCDFE6
      border-radius: 4px
      font-weight: 500
      font-size: 14px
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266
      &::placeholder
        color: #606266
    &.el-form-item
      margin-bottom: 20px!important
    .el-input__suffix
      display: flex
      justify-content: center
      align-items: center
    .el-input__suffix-inner
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    &.el-input--suffix .el-input__inner
      padding-right: 60px
    .el-input__suffix
      right: 13px
</style>
